<template>
  <div class="">
    <div class="h-48 fill_all w-full fixed bg-gray2 opacity-70 transition filter blur" v-if="!ShowErr"></div>
      <div class="flex justify-center relative z-50 pt-14" v-if="!ShowErr">
        <div class="card bg-white rounded-xl p-4 m-4 mt-0 pt-0 sm:w-auto lg:w-11/12 xl:w-10/12 2xl:w-9/12">
          <div class="relative">
            <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-8 w-full heading-2">
              #{{jobDetails.jobNumber}}
            </div>
          </div>
          <div class="mt-12">
            <div class="" v-if="jobDetails !== null">
              <div class="">
                <div class="  mb-4 grid grid-cols-12">
                  <div class="col-span-6">
                    <p class="text-primary heading-2 font-bold">{{OrgDetail.orgName}}</p>
                    <p class="text-text2">
                      {{OrgDetail.addressLine1 !== '' ? OrgDetail.addressLine1 +',' : ''}} {{OrgDetail.addressLine2 !== '' ? OrgDetail.addressLine2 + ',' : ''}}
                      </p>
                      <p class="text-text2">
                        {{OrgDetail.city !== '' ? OrgDetail.city + ',' : ''}} {{OrgDetail.state}} 
                      </p>
                      <p v-if="OrgDetail.country !== ''" class="text-text2">
                        {{OrgDetail.country !== '' ? OrgDetail.country + ',' : ''}} {{ OrgDetail.zip }}
                      </p>
                  </div>
                  <div class="col-span-6 text-right">
                    <Button class="my-1" :isSqure="true" :btnSize="'small'" style="letter-spacing: 1.4px;" :textColor="'white'" :btnColor="'primary'" :btnText="'DOWNLOAD'" @buttonAction="downloadInvoice()"/>
                  </div>
                </div>
                <div>
                  <div class="divider"></div>
                    <div class=" text-gray3 my-2 items-center">
                      <div class="">
                        <p class="text-text2 heading-2 font-bold">{{jobDetails.jobTitle}}</p>
                        <div class="">
                            <span class="flex items-center" v-if="jobDetails.companyName !== ''">
                              <p class="text-gray4">Customer:</p>
                              <p class="text-text2 pl-2">{{jobDetails.companyName}}</p>
                            </span>
                            <span class="flex items-center" v-if="jobDetails.customerFirstName !== '' || jobDetails.customerLastName !== ''">
                              <p class="text-gray4">Customer Name:</p>
                              <p class="text-text2 pl-2">{{jobDetails.customerFirstName}} {{jobDetails.customerLastName}}</p>
                            </span>
                            <div class="flex items-center">
                              <span class="flex items-center" v-if="jobDetails.houseNo !== ''">
                                <p class="text-gray4">#:</p>
                                <p class="text-text2 pl-2">{{jobDetails.houseNo}}, </p>
                              </span>
                              <span class="flex items-center" v-if="jobDetails.floorNumber !== ''">
                                <p class="text-gray4 pl-1"> (Floor:</p>
                                <p class="text-text2 pl-2">{{jobDetails.floorNumber}})</p>
                              </span>
                            </div>
                            <span class="flex items-center" v-if="jobDetails.buildingName !== ''">
                              <p class="text-gray4">Bldg:</p>
                              <p class="text-text2 pl-2">{{jobDetails.buildingName}}</p>
                            </span>
                            <p class="text-text2">
                              {{jobDetails.addressLine1 !== '' ? jobDetails.addressLine1 + ',' : ''}} {{jobDetails.addressLine2 !== '' ? jobDetails.addressLine2 + ',' : ''}}
                            </p>
                            <p class="text-text2">
                              {{jobDetails.city !== '' ? jobDetails.city + ',' : ''}} {{jobDetails.state}} {{ jobDetails.zip }}
                            </p>
                            <p v-if="jobDetails.country !== ''" class="text-text2">
                              {{jobDetails.country}}
                            </p>
                          </div>
                      </div>
                    </div>
                </div>
                <div>
                  <div class="divider mb-2"></div>
                  <div class="">
                    <div class=" flex table_containder" style="flex-flow:wrap;">
                      <div class="text-gray4 heading-5">{{ jobDetails.visitList.length > 0 ? 'Visits:' : 'Visit:' }}</div>
                      <div class="p-2 w-full rounded my-1 card hover:bg-gray-100" v-for="(name, index) in jobDetails.visitList" :key="index">
                        <div class="my-1 flex items-center" >
                          <div class=" heading-5 text-text2 font-semibold pr-2">
                            {{index + 1}}.
                          </div>
                          <div class="heading-5 text-text2 font-semibold">
                            <span v-html="dateFilter(name.visitStartDateTime, 'scheduleDate')" ></span>
                            <span>(</span><span v-html="dateFilter(name.visitStartDateTime, 'scheduleTime')" ></span>
                            <span>-</span>
                            <span v-html="dateFilter(name.visitEndDateTime, 'scheduleTime')" ></span><span>)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
     <div  v-if="ShowErr && firstLoading">
      <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
        <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
            <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
            <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Job Link and Try Again Later</p>
        </div>
      </div>
     </div>
  </div>
</template>
<script>
var moment = require('moment')
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
export default {
  components: {
    Button
  },
  data() {
    return {
      OrgDetail: {},
      jobDetails: {},
      ShowErr: true,
      firstLoading: false,
      screenWidth: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.myEventHandler()
    this.getInvoiceDetail()
  },
  methods: {
    getInvoiceDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.HashJobDetail(
        this.$route.params.hashId,
        response => {
          this.ShowErr = false
          this.firstLoading = true
          this.jobDetails = response.Data !== null ? response.Data : {}
          this.OrgDetail = response.Data.organizationDetail !== null ? response.Data.organizationDetail : {}
          document.title = 'Job: ' + this.jobDetails.jobTitle
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          if (error.message === 'No Record Found') {
            this.ShowErr = true
            this.firstLoading = true
          }
          this.ShowErr = true
          this.firstLoading = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    dateFilter (dateTime, value) {
      let checkDate = new Date(dateTime)
      let timeValue = moment(checkDate, 'ddd DD-MMM-YYYY, hh:mm a').format('hh:mm a')
      let todayDate = new Date().setHours(0, 0, 0, 0)
      var fullDate = moment(dateTime).format('MMM DD, ')
      let dayName = moment(dateTime).format('ddd')
      dateTime = new Date(dateTime).toUTCString()
      let requestDt = dateTime
      requestDt = new Date(requestDt).setHours(0, 0, 0, 0)
      if (value === 'scheduleDate') {
        return fullDate + ' ' + dayName
      } else if (value === 'scheduleTime') {
        return timeValue
      } else {
        if (moment(todayDate).diff(requestDt, 'days') === 0) {
          return 'Today ' + timeValue
        }
        let difDay = moment(todayDate).diff(requestDt, 'days')
        if (difDay > 1) {
          return fullDate + ' ' + timeValue
        }
        if (difDay === 1) {
          return 'Yesterday ' + timeValue
        }
      }
    },
    downloadInvoice () {
      // this.$store.dispatch('SetAlert', {showAlert: true, message: 'Download Option Not Available Right Now', color: 'error'})
      this.$router.push({name: 'DownloadHashJob', params: {hashId: this.$route.params.hashId}})
    },
    myEventHandler () {
      this.screenWidth = window.innerWidth
    }
  },
};
</script>
<style scoped>
.hash_col {
      min-width: 40px !important;
      width: 80px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .desc_col {
      min-width: 40px !important;
      width: 360px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .rate_col {
      min-width: 40px !important;
      width: 190px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .qty_col {
      min-width: 40px !important;
      width: 150px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .tax_col {
      min-width: 80px !important;
      width: 230px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .detail_col {
      min-width: 40px !important;
      width: 150px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .allTax_col {
      min-width: 40px !important;
      width: 230px !important;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      padding-right: 8px;
    }
    .loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>